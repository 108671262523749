
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class=" py-2 mb-4">Edit shop item</h1>

			<form @submit.prevent="updateShopItems" autocomplete="off">
				<v-layout row wrap>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_items.shop_item_barcode" type="number" :label="$store.getters.language.data.shop_items.shop_item_barcode" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_items.shop_item_quantity" type="number" :label="$store.getters.language.data.shop_items.shop_item_quantity" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_items.shop_item_note" type="text" :label="$store.getters.language.data.shop_items.shop_item_note" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_items.shop_item_price" type="text" :label="$store.getters.language.data.shop_items.shop_item_price" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['true','false']" v-model="shop_items.shop_item_fav" dense filled outlined item-text="shop_item_fav" item-value="shop_item_fav" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_fav">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['available','stock','zero_quantity']" v-model="shop_items.shop_item_status" dense filled outlined item-text="shop_item_status" item-value="shop_item_status" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_status">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="item_types" v-model="shop_items.item_type_id" dense filled outlined item-text="item_type_title" item-value="item_type_id" :return-object="false" :label="$store.getters.language.data.item_types.item_type_title">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="item_colors" v-model="shop_items.item_color_id" dense filled outlined item-text="item_color_title" item-value="item_color_id" :return-object="false" :label="$store.getters.language.data.item_colors.item_color_title">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="item_sizes" v-model="shop_items.item_size_id" dense filled outlined item-text="item_size_title" item-value="item_size_id" :return-object="false" :label="$store.getters.language.data.item_sizes.item_size_title">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="shop_items.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.shop_items.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shop_items.request.js'
	export default {
		data() {
			return {
				shop_items: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			item_types() {
				return this.$store.getters.item_types_list
			},

			item_colors() {
				return this.$store.getters.item_colors_list
			},

			item_sizes() {
				return this.$store.getters.item_sizes_list
			},

			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneShopItems()
		},
		methods: {
			getOneShopItems() {
				this.loading = true
				requests.getOneShopItems(this.id).then(r => {
					if (r.status == 200) {
						this.shop_items = r.data.row
						this.shop_items.shop_item_date = this.shop_items.shop_item_date.split('.')[0]
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateShopItems() {
				this.loading = true
				this.shop_items.user_update_id = this.user.user_id
				requests.updateShopItems(this.id, this.shop_items).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'ShopItems Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    
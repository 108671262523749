
import axios from 'axios';
export default {
    
    async createShopItems(params)  {
        return await axios.post(`shop_items/create` , params)
    },
    async createShopItemsList(params)  {
        return await axios.post(`shop_items/create/list` , params)
    },
    async updateShopItemsColumn(column , value , data)  {
        return await axios.put(`shop_items/update_list?${column}=${value}` , data)
    },
    async deleteShopItemsList(list)  {
        return await axios.delete(`shop_items/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopItems(column , value)  {
        return await axios.get(`shop_items/report?${column}=${value}`)
    },
    async getAllShopItems()  {
        return await axios.get(`shop_items/all`)
    },
    async getAllShopItemsView()  {
        return await axios.get(`shop_items/allview`)
    },
    async getOneShopItems(shop_item_id)  {
        return await axios.get(`shop_items/all/${shop_item_id}`)
    },
    async getShopItemsByColumn(column , value)  {
        return await axios.get(`shop_items/filter?column=${column}&value=${value}`)
    },
    async deleteShopItems(shop_item_id)  {
        return await axios.delete(`shop_items/delete/${shop_item_id}`)
    },
    async updateShopItems(shop_item_id , params)  {
        return await axios.put(`shop_items/update/${shop_item_id}` , params)
    }
}